.cards-container {
    margin-top: 20px;
  }
  
  .cards-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards in a row */
    gap: 20px; /* Space between cards */
    margin-top: 20px; /* Space between header and cards */
  }
  
  .card {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .card h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .card p {
    margin: 5px 0;
    font-size: 0.9rem;
  }
  