
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  @include vendor("appearance", "none");
  @include vendor(
    "transition",
    (
      "background-color #{_duration(transition)} ease",
      "box-shadow #{_duration(transition)} ease",
      "color #{_duration(transition)} ease"
    )
  );
  background-color: transparent;
  border: 0;
  box-shadow: inset 0 0 0 1px _palette(border);
  color: _palette(fg-bold) !important;
  cursor: pointer;
  display: inline-block;
  font-family: _font(family-heading);
  font-size: 0.6em;
  font-weight: _font(weight-heading-bold);
  height: _size(element-height) * 1.75;
  letter-spacing: _font(kerning-heading);
  line-height: _size(element-height) * 1.75;
  padding: 0 2.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    box-shadow: inset 0 0 0 1px _palette(accent);
    color: _palette(accent) !important;

    &:active {
      background-color: transparentize(_palette(accent), 0.95);
    }
  }

  &:before,
  &:after {
    color: _palette(fg-light);
    position: relative;
  }

  &:before {
    left: -1em;
    padding: 0 0 0 0.75em;
  }

  &:after {
    left: 1em;
    padding: 0 0.75em 0 0;
  }

  &.fit {
    display: block;
    margin: 0 0 (_size(element-margin) * 0.5) 0;
    width: 100%;
  }

  &.big {
    font-size: 0.7em;
    padding: 0 3em;
  }

  &.small {
    font-size: 0.5em;
  }

  &.disabled,
  &:disabled {
    @include vendor("pointer-events", "none");
    color: _palette(border) !important;

    &:before {
      color: _palette(border) !important;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
}

.libutton,
.youtube-button {
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  color: white;
  background-color: #0073b1; /* LinkedIn Blue */
  border-radius: 5px;
  transition: background-color 0.3s;
}

.youtube-button {
  background-color: #FF0000; /* YouTube Red */
}

.libutton:hover {
  background-color: #d9e8ee;
}

.youtube-button:hover {
  background-color: #f2e9e9;
}

.resumebutton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333; /* Text color */
  background-color: transparent; /* Transparent background */
  border: 2px solid #333; /* Clear border */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  cursor: pointer;
}

.resumebutton:hover {
  background-color: #333; /* Dark background on hover */
  color: #fff; /* White text on hover */
}

.technologies {
  margin: 20px 0;
  text-align: center;
}

.technologies h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 8 cards in a row */
  gap: 15px;
  justify-content: center;
}

.tech-card {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tech-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
